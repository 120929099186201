<ng-template
  cdkConnectedOverlay
  [(cdkConnectedOverlayOpen)]="show"
  (backdropClick)="toggle(false)"
  [cdkConnectedOverlayOrigin]="trigger().host"
  cdkConnectedOverlayPanelClass="ui-menu-overlay-panel"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOffsetY]="offsetY()"
  [cdkConnectedOverlayOffsetX]="offsetX()"
  cdkConnectedOverlayHasBackdrop
>
  <ng-content />
</ng-template>
