import { CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  NgModule,
  ViewEncapsulation,
  inject,
  input,
  model,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { fromEvent } from "rxjs";

import { Directive } from "@angular/core";

@Directive({
  standalone: true,
  selector: "[uiMenuTrigger]",
  hostDirectives: [CdkOverlayOrigin],
  exportAs: "uiMenuTrigger",
  host: {
    style: "user-select: none;",
  },
})
export class MenuTrigger {
  protected ref = inject(ElementRef<HTMLElement>);

  get host() {
    return this.ref.nativeElement;
  }
}

// TODO: ongoing development for this!!

@Component({
  standalone: true,
  selector: "ui-menu-overlay",
  styleUrl: "menu-overlay.ui.scss",
  templateUrl: "menu-overlay.ui.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OverlayModule, NgTemplateOutlet, MenuTrigger],
})
export class MenuOverlayUi implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  readonly show = model<boolean>(false);
  readonly trigger = input.required<MenuTrigger>();
  readonly offsetY = input(10);
  readonly offsetX = input(0);

  toggle(v?: boolean): void {
    this.show.set(v ?? !this.show());
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.trigger().host, "click")
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.toggle();
      });
  }
}

@NgModule({
  imports: [MenuOverlayUi, MenuTrigger],
  exports: [MenuOverlayUi, MenuTrigger],
})
export class MenuOverlayUiModule {}
